*{
    box-sizing: border-box;
    padding: 0px;
    margin: 0px;
    /* font-family: 'Inter' !important; */
    font-style: normal !important;
}

/* body{
    max-width: 1950px;
    margin: auto;
} */

/* .logo_img{
    max-width: 160px; 
    height: auto; 
    object-fit: cover;
} */

.button1{
    font-size: 0.9rem;
    margin-right: 26px; 
    border-radius: 15px; 
    border: 1px solid #3B697C; 
    color: #3B697C; 
    font-weight: 600;
    padding-top: 8px !important;
    padding-bottom: 8px !important;
}
.button1:hover{
    color: white;
    background-color: #3B697C;
}
.button1:focus{
    background-color: #BBE6F9;
    color: white;
    outline: none !important;
    box-shadow: none;
}



.button2{
    font-size: 0.9rem;
    margin-right: 18px; 
    border-radius: 15px;
    border: 1px solid #41A991; 
    color: #41A991; 
    font-weight: 600;
    padding-top: 8px !important;
    padding-bottom: 8px !important;
}

.button2:hover{
    color: white;
    background-color: #3B7C6D;
}
.button2:focus{
    background-color: #BBF9EA;
    color: white;
    outline: none !important;
    box-shadow: none;
}

.button3{
    padding: 0px;
}

.button3:focus{
    outline: none !important;
    box-shadow: none;
}
.sign_out_btn{
    border: 1px solid #4893B3;
    border-radius: 24px;
    padding: 8px 10px;
    /* font-family: 'Inter'; */
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    color: #4893B3 !important;
}
.sign_out_btn:hover{
   
    background-color: #4893B3;
    padding: 8px 10px;
   
    /* identical to box height */

    display: flex;
    align-items: center;
    text-align: center;
    color: white !important;
    outline: none;
    box-shadow: none;
}

.sign_out_btn2{
    /* background: #8EF3DB; */
    border: 1px solid #2CE0B6;
    border-radius: 24px;
    padding: 5px 10px;
    /* font-family: 'Poppins'; */
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    color: #2CE0B6 !important;
}
.sign_out_btn2:hover{
   
    background-color: #2CE0B6;
    padding: 8px 10px;
   
    /* identical to box height */

    display: flex;
    align-items: center;
    text-align: center;
    color: white !important;
    outline: none;
    box-shadow: none;
}
.sign_out_btn:focus{
   
    background-color: #4893B3;
    padding: 8px 10px;
    display: flex;
    align-items: center;
    text-align: center;
    color: white;
    outline: none;
    box-shadow: none;
}


.copyright{
    /* font-family: 'Inter' !important; */
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 14px !important;
    line-height: 21px !important;
    text-decoration: none; 
    color:white;
}


.footer_ul li{

    margin-right: 15px !important; 
    font-size: 14px !important;
    display:inline !important;
}
.footer_ul li a{
    /* font-family: 'Inter' !important; */
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 14px !important;
    line-height: 21px !important;
    text-decoration: none; 
    color:white;
}


.footer_div2 li{

    margin-right: 15px !important; 
    font-size: 14px !important;
    display:inline !important;
}
.footer_div2 li a{
    /* font-family: 'Inter' !important; */
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 14px !important;
    line-height: 21px !important;
    text-decoration: none; 
    color:white;
}

.footer_css{
    background-color:#4893B3; 
    color: #FFFFFF; 
    margin-top: 10px; 
    padding:10px 40px;
}
@media (max-width: 400px) and (min-width: 100px) {
    
    .footer_css{
        background-color:#4893B3; 
        color: #FFFFFF; 
        margin-top: 40px; 
        padding:20px 20px;
    }
    .numbers_size{
       font-size: 20px;
    }
    
    .description{
        font-size: 15px; 
    }
   
}

