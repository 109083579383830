.create-account{
    cursor: pointer;
}
#console {display: none;}
#dialog {
  position: absolute;
  z-index: 9999;
  top: 0;
  left: 0;
  width: 100%;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.521);
  transition: visibility 0s linear 0.5s,opacity 0.5s linear;
  opacity: 0;
  visibility: hidden;
}
.dialog_state {
  visibility: hidden;
  opacity: 0;
  display: none;
}
.dialog_state:checked + #dialog,
#dialog.dialog_open {
  transition-delay:0s;
  opacity: 1;
  visibility: visible;
}
#dlg-back {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  width: 100vw;
  height: 100vh;
  cursor: pointer;
  color: black;
}
.dialog_state:checked + #dialog #dlg-wrap {
  max-height: 24rem;
  opacity: 1;
  padding: 2rem;
}
#dlg-wrap {
  position: relative;
  z-index: 1;
  display: block;
  box-sizing: border-box;
  margin: 0 auto;
  top: 50%;
  transform: translateY(-50%);
  width: 450px;
  padding: 2rem;
  border-radius: 0.25rem;
  /*border: 1px solid #969696;*/
  background: #fff;
  box-shadow: 1px 1px 6px rgba(0,0,0,0.3);
  max-height: 0;
  opacity: 0;
  padding-top: 0;
  padding-bottom: 0;
  overflow: hidden;
  transition: all .5s;
}
#dlg-close {
  position: absolute;
  top: 0;
  right: 0;
  width: 2rem;
  height: 2rem;
  line-height: 2rem;
  text-align: center;
  cursor: pointer;
  color:black
}
h3#dlg-header {

  text-transform: initial;
  letter-spacing: -0.025em;
  margin: 0 0 1rem;
  color:black
}
#dlg-content {
  /* font-weight: 300; */

  /* letter-spacing: 0.015em; */
}
#dlg-prompt .button {
  margin: 1rem 0.5rem;
  border-radius: 1.5rem;
}



.main_area {
  transition: all 0.5s ease-out;
}
.dialog_state:checked ~ .main_area,
.main_area.dialog_open {
  filter: blur(6px);
}

.button, a.button {
  display: inline-block;
  padding: 0.25em 1em;
  box-sizing: border-box;
  
  background: #ffffff;
  /* color: #606060; */
  text-decoration: none;
  letter-spacing: 0.075em;
  line-height: 2rem;

  font-weight: 300;
  font-size: 0.85em;
  border-radius: 4px;
  border: 1px solid #e0e0e0;
  
  transition: .1s all;
  cursor: pointer;
}
.button:hover, a.button:hover {
  color: #a0a0a0;
  background: #f8f8f8;
  box-shadow: 0 1px 1px rgba(0,0,0,0.05);
  /*transform: scale(1.01);*/
}
.button.positive, a.button.positive {
  border-color: #6199B7;
  color: #6199B7;

}
.button.positive:hover, a.button.positive:hover {
  border-color: #468FB6;
  color: #fff;
  background-color: #468FB6;
}

.icon {
  vertical-align: middle;
  line-height: inherit;
  font-size: 1.75em;
}

/* #main {
  height: 100vh;
  width: 100%;
  box-sizing: border-box;
  overflow: hidden;
  background-size: cover;
} */
#main .center {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
}
/* #h1 {

  text-transform: uppercase;
  letter-spacing: -0.05em;
  margin: 2rem 0 0.5rem;
}
#h2 {

  text-transform: uppercase;
  letter-spacing: 0.0em;
  margin: 2rem 0 0.5rem;
} */

